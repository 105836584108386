import React from 'react'
import { Grid } from '@mui/material'
import { Device } from 'components/shared/Device'
import useUserData from 'hooks/useUserData'
import TabletIcon from '@mui/icons-material/Tablet'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import DevicesIcon from '@mui/icons-material/Devices'
import LaptopIcon from '@mui/icons-material/Laptop'
import connected from 'assets/images/connected.png'
import disconnected from 'assets/images/disconnected.png'
import signal_0 from 'assets/images/signal_0.png'
import signal_1 from 'assets/images/signal_1.png'
import signal_2 from 'assets/images/signal_2.png'
import signal_3 from 'assets/images/signal_3.png'
import signal_5_4 from 'assets/images/signal_5_4.png'

const DisconnectedDevices = () => {
  const { connectedDevices } = useUserData()
  return (
    <>
      {connectedDevices
        ?.filter((device) => !device.connected)
        .map((device, index) => {
          let deviceName =
            device.nickname !== null
              ? device.nickname
              : device.hostname !== null
              ? device.hostname
              : device.manufacturer !== null
              ? device.manufacturer
              : device.device_type !== null
              ? device.device_type
              : 'Desconocido'
          let deviceManufacturer =
            device.device_type !== null
              ? device.device_type
              : device.manufacturer !== null
              ? device.manufacturer
              : device.hostname !== null
              ? device.hostname
              : device.nickname !== null
              ? device.nickname
              : 'Desconocido'
          let deviceIcon
          switch (true) {
            case ['tablet'].some((el) => device?.device_type?.includes(el)):
              //case device.device_type.includes === 'tablet' || :
              deviceIcon = <TabletIcon />
              break
            case ['phone', 'smartphone'].some((el) =>
              device?.device_type?.includes(el)
            ):
              deviceIcon = <PhoneIphoneIcon />
              break
            case ['laptop'].some((el) => device?.device_type?.includes(el)):
              deviceIcon = <LaptopIcon />
              break
            default:
              deviceIcon = <DevicesIcon />
              break
          }
          let deviceSignalIcon
          switch (true) {
            case ['wifi', 'wireless'].some(
              (
                el /// Si es conectado por wifi
              ) => device.connection_type.includes(el)
            ):
              if (
                device.connected === true ||
                device.connected === 'connected'
              ) {
                switch (true) {
                  case device.connectivity === 1:
                    deviceSignalIcon = signal_1
                    break
                  case device.connectivity === 2:
                    deviceSignalIcon = signal_2
                    break
                  case device.connectivity === 3:
                    deviceSignalIcon = signal_3
                    break
                  case device.connectivity >= 4:
                    deviceSignalIcon = signal_5_4
                    break
                  default:
                    deviceSignalIcon = signal_0
                    break
                }
              } else {
                deviceSignalIcon = signal_0
              }
              break /// Si es conectado por wifi
            default: /// Si es conectado por cable
              if (
                device.connected === true ||
                device.connected === 'connected'
              ) {
                deviceSignalIcon = connected
              } else {
                deviceSignalIcon = disconnected
              }
              break /// Si es conectado por cable
          }

          return (
            <Grid item xs={12} key={index}>
              <Device
                name={deviceName}
                brand={deviceManufacturer}
                icon={deviceIcon}
                frequencyIcon={deviceSignalIcon}
                frequency={''}
              />
            </Grid>
          )
        })}
    </>
  )
}

export default DisconnectedDevices
