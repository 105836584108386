/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ReportProblem from 'components/shared/ReportProblem'
import Status from './Status'
import WifiName from './WifiName'
import NetworkDevicesConnected from './NetworkDevicesConnected'
import ConnectedDevices from './ConnectedDevices'
import SpeedTest from './SpeedTest'
import useUserData from 'hooks/useUserData'
import useWifiData from '../hooks/useWifiData'

const WifiStatus = () => {
  const { enabled } = useWifiData()
  const { isGlobalLoading } = useUserData()

  return (
    <div className='wifiContent-status'>
      {isGlobalLoading && !enabled && <></>}
      {!isGlobalLoading && enabled && (
        <>
          <Status />
          <WifiName />
          <NetworkDevicesConnected />
          <ConnectedDevices />
          <SpeedTest />
          <ReportProblem />
        </>
      )}
    </div>
  )
}

export default WifiStatus
