import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import useUserData from 'hooks/useUserData'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import IconButton from '@mui/material/IconButton'
import { TnetworkDevice } from 'types/types'

const NetworkDevicesConnected = () => {
  const { networkDevices, networkData } = useUserData()
  const navigate = useNavigate()

  return (
    <div className='wifiContent-status-networkDevicesConnected'>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <h4 className='networkDevicesConnected-title axtel-text'>
            Equipos de WiFi conectados
          </h4>
          <h5
            className='networkDevicesConnected-connected'
            style={{
              color:
                networkData.health.internet_status === 'connected'
                  ? '#2CBE5D'
                  : '#E6204F'
            }}
          >
            {networkDevices.filter((device: TnetworkDevice) => device.connected)
              .length +
              ' de ' +
              networkDevices.length}
          </h5>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            className='navigationButton axtel-icon-rounded'
            //color='primary'
            aria-label='upload picture'
            component='label'
            onClick={() => navigate('/wifi/networkdevices')}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default NetworkDevicesConnected
