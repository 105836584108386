import React from 'react'
import { Grid } from '@mui/material'
import greenDot from 'assets/images/greenDot.png'
import redDot from 'assets/images/redDot.png'
import { NetworkDevice } from 'components/shared/NetworkDevice'
import useUserData from 'hooks/useUserData'
import { TnetworkDevice } from 'types/types'

const NetworkDevices = () => {
  const { networkDevices } = useUserData()

  return (
    <div className='serviceStatusContent-networkDevices'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='serviceStatusContent-networkDevices-title'>
            <h4 className='networkDevices-title'>Equipos de WiFi conectados</h4>
          </div>
        </Grid>
        <Grid item container xs={12} sx={{ paddingTop: '3px !important' }}>
          {networkDevices.map((device: TnetworkDevice, index: number) => {
            return (
              <NetworkDevice
                key={index}
                location={device.location}
                type={device.gateway ? 'Principal' : 'Secundario'}
                led={device.connected && device.signal > 0 ? greenDot : redDot}
              />
            )
          })}
        </Grid>
      </Grid>
    </div>
  )
}

export default NetworkDevices
