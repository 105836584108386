import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from 'hooks/usePrivateAxios'
import useUserData from 'hooks/useUserData'
import { notify } from 'utils/notify'
import { urls } from 'utils/urls'
import validator from 'validator'

const useForgetPassword = () => {
  const privateAxios = useAxiosPrivate()
  const { setGlobalLoading } = useUserData()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')

  const handleNewResetPassword = async () => {
    try {
      setGlobalLoading(true)
      const resetPassword = await privateAxios.put(urls.resetCustomerPassword, {
        email
      })
      if (resetPassword.data.success) {
        notify(
          'success',
          'Hemos enviado una liga a tu correo para restablecer tu contraseña.'
        )
        navigate('/login')
      } else {
        throw new Error('Error al procesar la solicitud')
      }
    } catch (error) {
      notify('error', 'Error al procesar la solicitud')
    } finally {
      setGlobalLoading(false)
    }
  }

  const isSubmitDisabled = () => {
    return email === '' || !validator.isEmail(email)
  }

  return { email, setEmail, handleNewResetPassword, isSubmitDisabled }
}

export default useForgetPassword
