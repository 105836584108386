import { TinitialState, TReducerAction } from './types'

export const reducer = (
  globalState: TinitialState,
  action: TReducerAction
): TinitialState => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...globalState,
        isGlobalLoading: action.payload
      }
    case 'SET_SPEEDTESTS':
      return {
        ...globalState,
        speedTests: action.payload
      }

    case 'SET_OPTIONMENUACTIVE':
      return {
        ...globalState,
        optionMenuActive: action.payload
      }
    case 'SET_CONNECTEDDEVICES':
      return {
        ...globalState,
        connectedDevices: action.payload
      }

    case 'SET_USERDATA':
      return {
        ...globalState,
        userData: action.payload
      }

    case 'SET_NETWORKDATA':
      return {
        ...globalState,
        networkData: action.payload
      }

    case 'SET_INTERNETSCORE':
      return {
        ...globalState,
        internetScore: action.payload
      }

    case 'SET_NETWORKDEVICES':
      return {
        ...globalState,
        networkDevices: action.payload
      }
    default:
      return globalState
  }
}
