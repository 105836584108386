import React from 'react'
import { Grid } from '@mui/material'
import greenDot from 'assets/images/greenDot.png'
import redDot from 'assets/images/redDot.png'
import { NetworkDevice } from 'components/shared/NetworkDevice'
import useUserData from 'hooks/useUserData'
import ReportProblem from 'components/shared/ReportProblem'

const NetworkDevices = () => {
  const { networkDevices } = useUserData()

  return (
    <div className='wifiContent-networkDevices'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='networkDevices-title'>
            <h4 className='status-title axtel-text'>
              Equipos de WiFi conectados
            </h4>
          </div>
        </Grid>

        <Grid
          item
          container
          xs={12}
          sx={{
            paddingTop: '30px !important',
            paddingBottom: '30px !important',
            height: networkDevices.length <= 3 ? 'auto' : '400px',
            overflowY: 'scroll'
          }}
        >
          {networkDevices.map((device: any, index: any) => {
            return (
              <NetworkDevice
                key={index}
                location={device.location}
                type={device.gateway ? 'Principal' : 'Secundario'}
                led={device.connected && device.signal > 1 ? greenDot : redDot}
              />
            )
          })}
        </Grid>
      </Grid>
      <ReportProblem />
    </div>
  )
}

export default NetworkDevices
