import React from 'react'
import { Grid, Stack } from '@mui/material'

interface networkDevice {
  location: string
  type: string
  led: string
}

export const NetworkDevice = ({ location, type, led }: networkDevice) => {
  return (
    <Grid item xs={12}>
      <div className='networkDevice'>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <div>
            <h4 className='networkDevice-title axtel-text'>{location}</h4>
            <h6 className='networkDevice-subtitle axtel-text'>{type}</h6>
          </div>
          <img alt='' src={led} />
        </Stack>
      </div>
    </Grid>
  )
}
