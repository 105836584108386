/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from 'hooks/usePrivateAxios'
import useUserData from 'hooks/useUserData'
import { notify } from 'utils/notify'
import { urls } from 'utils/urls'
import validator from 'validator'

const useSetPassword = (token: string) => {
  const { setGlobalLoading } = useUserData()
  const privateAxios = useAxiosPrivate()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [enabled, setEnabled] = useState(false)
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: ''
  })

  useEffect(() => {
    handleValidateToken()
  }, [token])

  const handleValidateToken = async () => {
    try {
      setGlobalLoading(true)
      const validateToken = await privateAxios.get(
        urls.validateCustomerPasswordToken,
        { params: { token } }
      )
      if (validateToken.data.success) {
        setEmail(validateToken.data.email)
        setEnabled(true)
      } else {
        setEnabled(false)
      }
    } catch (error) {
      notify('error', 'No se pudo validar el token')
    } finally {
      setGlobalLoading(false)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswords((prevEvent) => ({
      ...prevEvent,
      [e.target.name]: e.target.value
    }))
  }

  const handleUpdatePassword = async () => {
    try {
      if (passwords.newPassword !== passwords.confirmPassword) {
        notify('error', 'Las contraseñas deben coincidir')
        return false
      }
      setGlobalLoading(true)
      const validateToken = await privateAxios.put(
        urls.updateCustomerPassword,
        { email, password: passwords.newPassword, token }
      )
      if (validateToken.data.success) {
        notify('success', 'Contraseña guardada')
        navigate('/login')
      } else {
        notify('error', 'Al parecer la liga ha expirado')
      }
    } catch (error) {
      notify('error', 'No se pudo validar el token')
    } finally {
      setGlobalLoading(false)
    }
  }

  const isPasswordValid = (password: string) => {
    return validator.isStrongPassword(password, {
      minLength: 6,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1
    })
  }

  const isSubmitDisabled = () => {
    return (
      passwords.newPassword === '' ||
      passwords.confirmPassword === '' ||
      email === '' ||
      !isPasswordValid(passwords.newPassword)
    )
  }

  return {
    isPasswordValid,
    handleUpdatePassword,
    handleChange,
    handleValidateToken,
    isSubmitDisabled,
    email,
    enabled,
    passwords
  }
}

export default useSetPassword
