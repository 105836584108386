/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, IconButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ReportProblem from 'components/shared/ReportProblem'
import useUserData from 'hooks/useUserData'

const ServiceStatus = () => {
  const navigate = useNavigate()
  const { setOptionMenuActive, isGlobalLoading, setGlobalLoading } =
    useUserData()

  useEffect(() => {
    setOptionMenuActive(3)
    isGlobalLoading && setGlobalLoading(false)
  }, [])

  return (
    <div className='supportContent'>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item container xs justifyContent='center' alignItems='center'>
          <IconButton
            className='navigationButton axtel-icon-rounded'
            aria-label='upload picture'
            component='label'
            onClick={() => navigate(-1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <h3 className='supportContent-title axtel-text'>Soporte</h3>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
      <div className='content-components'>
        <ReportProblem />
      </div>
    </div>
  )
}

export default ServiceStatus
