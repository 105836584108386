import axios from 'axios'
const baseCustomerUrl = process.env.REACT_APP_API_CUSTOMER_URL
const baseNetworkUrl = process.env.REACT_APP_API_NETWORK_URL
const basePaynetUrl = process.env.REACT_APP_API_PAYNET_URL
const baseProductsUrl = process.env.REACT_APP_API_PRODUCTS_URL
const baseFieldServicesUrl = process.env.REACT_APP_API_FIELDSERVICES_URL

const baseBillingUrl = process.env.REACT_APP_API_BILLING_URL

const paynetToken = process.env.REACT_APP_API_PAYNET_TOKEN
const productsToken = process.env.REACT_APP_API_PRODUCTS_TOKEN
const billingToken = process.env.REACT_APP_API_BILLING_TOKEN

const paynetHeaders = {
  Authorization: `Bearer ${paynetToken}`
}
const productsHeaders = {
  Authorization: `Bearer ${productsToken}`
}

const billingHeaders = {
  Authorization: `Bearer ${billingToken}`
}

const publicAxios = axios.create({
  baseURL: baseCustomerUrl,
  withCredentials: true
})

const privateAxios = axios.create({
  baseURL: baseCustomerUrl,
  withCredentials: true
})

const privateAxiosNetwork = axios.create({
  baseURL: baseNetworkUrl,
  withCredentials: false
})

const privateAxiosPaynet = axios.create({
  baseURL: basePaynetUrl,
  headers: paynetHeaders,
  withCredentials: false
})

const privateAxiosBilling = axios.create({
  baseURL: baseBillingUrl,
  headers: billingHeaders,
  withCredentials: false
})

const privateAxiosProducts = axios.create({
  baseURL: baseProductsUrl,
  headers: productsHeaders,
  withCredentials: false
})

const privateAxiosFieldServices = axios.create({
  baseURL: baseFieldServicesUrl,
  withCredentials: false
})

export {
  publicAxios,
  privateAxios,
  privateAxiosNetwork,
  privateAxiosPaynet,
  privateAxiosProducts,
  privateAxiosBilling,
  privateAxiosFieldServices
}
