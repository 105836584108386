import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import useUserData from 'hooks/useUserData'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import IconButton from '@mui/material/IconButton'
import { TconnectedDevice } from 'types/types'

const ConnectedDevices = () => {
  const { connectedDevices } = useUserData()
  const navigate = useNavigate()

  return (
    <div className='wifiContent-status-devicesConnected'>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <h4 className='devicesConnected-title'>Dispositivos conectados</h4>
          <h5
            className='devicesConnected-connected'
            style={{
              color:
                connectedDevices?.filter(
                  (device: TconnectedDevice) => device.connected
                ).length > 0
                  ? '#2CBE5D'
                  : '#E6204F'
            }}
          >
            {
              connectedDevices?.filter(
                (device: TconnectedDevice) => device.connected
              ).length
            }
          </h5>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            className='navigationButton axtel-icon-rounded'
            //color='primary'
            aria-label='upload picture'
            component='label'
            onClick={() => navigate('/wifi/devices')}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default ConnectedDevices
