/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MyService from './MyService/MyService'
import ServiceScore from './ServiceScore/ServiceScore'
import ServiceStatus from './ServiceStatus/ServiceStatus'
import ReportProblem from 'components/shared/ReportProblem'
import MyUserData from './MyUserData/MyUserData'
import useUserData from 'hooks/useUserData'
import { Grid } from '@mui/material'
import _ from 'lodash'
import Suspended from './Suspended/Suspended'
import { Button } from '@mui/material'

const Home = () => {
  const {
    setOptionMenuActive,
    isGlobalLoading,
    userData,
    internetScore,
    networkData,
    networkDevices,
    setGlobalLoading
  } = useUserData()

  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    setOptionMenuActive(0)
    if (
      !_.isEmpty(internetScore) &&
      !_.isEmpty(networkData) &&
      !_.isEmpty(networkDevices)
    ) {
      setEnabled(true)
      isGlobalLoading && setGlobalLoading(false)
    } else {
      //setEnabled(false)
    }
    setEnabled(true)
  }, [userData])

  useEffect(() => {
    isGlobalLoading && setGlobalLoading(false)
  }, [])

  return (
    <div className='homeContent'>
      <h3 className='home-title axtel-text'>Inicio</h3>
      {isGlobalLoading && <></>}
      {!isGlobalLoading && enabled && (
        <div className='content-components'>
          <Grid
            container
            spacing={1}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Grid item lg={5} xs={12}>
              {['SUSPENDED', 'LAST_PAYMENT_FAIL'].includes(
                userData.statusDebt
              ) ? (
                <Suspended />
              ) : (
                !_.isEmpty(networkData) && <ServiceStatus />
              )}
            </Grid>
            <Grid item lg={5} xs={12}>
              {!_.isEmpty(networkData) && <ServiceScore />}
              {/* <ServiceScore /> */}
            </Grid>
            <Grid item lg={10} xs={12}>
              <ReportProblem />
            </Grid>
            <Grid item lg={10} xs={12}>
              <MyService />
            </Grid>
            <Grid item lg={10} xs={12}>
              <MyUserData />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default Home
