import { privateAxiosNetwork } from '../utils/axios'
//import { useEffect } from 'react'
//import useRefreshToken from './useRefreshToken'
//import useUserData from './useUserData'

const useAxiosPrivateNetwork = () => {
  //const refresh = useRefreshToken()
  //const { auth } = useUserData()

  /*   useEffect(() => {
    const responseIntercept = privateAxiosNetwork.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          //await refresh()
          return privateAxiosNetwork(prevRequest)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      privateAxiosNetwork.interceptors.response.eject(responseIntercept)
    }
  }, [auth]) */
  //}, [auth, refresh])

  return privateAxiosNetwork
}

export default useAxiosPrivateNetwork
