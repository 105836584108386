import React from 'react'
import { Grid } from '@mui/material'
import useUserData from 'hooks/useUserData'

const WifiName = () => {
  const { networkData } = useUserData()
  return (
    <div className='wifiContent-status-wifiName'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className='wifiName-title axtel-text'>Nombre de la red</h4>
          <h5
            className='wifiName-status'
            style={{
              color:
                networkData.health.internet_status === 'connected'
                  ? '#2CBE5D'
                  : '#E6204F'
            }}
          >
            {networkData?.name}
          </h5>
        </Grid>
      </Grid>
    </div>
  )
}

export default WifiName
