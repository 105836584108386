import React from 'react'
import { Grid, Stack } from '@mui/material'

interface device {
  name: string
  brand: string
  icon: JSX.Element
  frequencyIcon: string
  frequency: string | number
}

export const Device = ({
  name,
  brand,
  icon,
  frequencyIcon,
  frequency
}: device) => {
  return (
    <div className='device'>
      <Grid container alignItems='center'>
        <Grid item xs={10}>
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={1}
          >
            {icon}
            <div>
              <h4 className='device-title axtel-text'>{name}</h4>
              <h6 className='device-subtitle axtel-text'>{brand}</h6>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <img className='device-frequencyIcon' alt='' src={frequencyIcon} />
          <h6 className='device-frequency axtel-text'>{frequency}</h6>
        </Grid>
      </Grid>
    </div>
  )
}
