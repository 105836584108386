import React from 'react'
import { Box, Grid } from '@mui/material'
import InternetScoreRating from 'components/shared/InternetScoreRating'

const Score = () => {
  return (
    <div className='serviceScoreContent-serviceScore'>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <h4 className='serviceScore-subtitle axtel-text'>
            Calificación de velocidad
          </h4>

          <Box
            sx={{
              '& > legend': { mt: 2 }
            }}
          >
            <InternetScoreRating />
          </Box>
          <h6 className='serviceScore-ago axtel-text'>Historial</h6>
        </Grid>
      </Grid>
    </div>
  )
}

export default Score
