/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, IconButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import useUserData from 'hooks/useUserData'

const Wifi = ({
  children
}: {
  children: ReactElement | ReactElement[] | undefined
}) => {
  const { setOptionMenuActive, isGlobalLoading } = useUserData()
  const navigate = useNavigate()
  useEffect(() => {
    setOptionMenuActive(1)
  }, [])
  return (
    <div className='wifiContent'>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item container xs justifyContent='center' alignItems='center'>
          <IconButton
            className='navigationButton axtel-icon-rounded'
            aria-label='upload picture'
            component='label'
            onClick={() => navigate(-1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <h3 className='wifiContent-title axtel-text'>WiFi</h3>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
      <div className='content-components'>{children}</div>
    </div>
  )
}

export default Wifi
