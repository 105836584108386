/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Grid, TextField, Button, Typography } from '@mui/material'
import useForgetPassword from './hooks/useForgetPassword'
import axtelLogo from 'assets/images/axtel-logo-blanco.png'

const ForgetPassword = () => {
  const { email, setEmail, handleNewResetPassword, isSubmitDisabled } =
    useForgetPassword()

  return (
    <div className='SetPasswordContent axtel-background'>
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={10} md={6} lg={6}>
          <img src={axtelLogo} alt='' style={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={7} lg={7} style={{ textAlign: 'center' }}>
          <Typography variant='h4' style={{ color: 'white' }}>
            Portal Clientes
          </Typography>
          <br />
          <br />

          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={4}
          >
            <Grid item xs={10}>
              <Typography
                variant='h6'
                style={{ textAlign: 'center', color: 'white' }}
              >
                Ingresa tu correo electrónico
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    color: 'white'
                  }
                }}
                id='forgetPassword-email'
                label='Correo electrónico'
                className='axtel-textfield-white'
                name='email'
                variant='standard'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={10}>
              <Button
                fullWidth
                disabled={isSubmitDisabled()}
                className={!isSubmitDisabled() ? 'axtel-button-full' : ''}
                type='submit'
                size='large'
                onClick={handleNewResetPassword}
              >
                Restablecer contraseña
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ForgetPassword
