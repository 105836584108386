import { useState } from 'react'
import usePrivateAxiosNetwork from 'hooks/usePrivateAxiosNetwork'
import useUserData from 'hooks/useUserData'
import { networkUrlBuilder } from 'utils/urls'
import { sleep } from 'utils/utils'
import { notify } from 'utils/notify'
//import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import useGoogleTagManager from 'hooks/useGoogleTagManager'

const useSpeedTest = () => {
  const privateAxiosNetwork = usePrivateAxiosNetwork()
  const [speedTestInProgress, setSpeedTestInProgress] = useState<boolean>(false)
  const { speedTests, userData, setSpeedTests } = useUserData()
  //const { newEvent } = useGoogleAnalytics()
  const { newEvent } = useGoogleTagManager()

  const handleStartNewSpeedTest = async () => {
    try {
      newEvent('action_buttons', 'start_speedtest')
      let lastSpeedTest = speedTests[0],
        foundNewSpeedTestResult = false
      const { url, params } = networkUrlBuilder(
        userData.network,
        'newSpeedTest'
      )
      await privateAxiosNetwork.post(url, params)
      setSpeedTestInProgress(true)
      while (foundNewSpeedTestResult === false) {
        await sleep(6000)
        let contructor = networkUrlBuilder(userData.network, 'getSpeedTests')
        const speedTests = await privateAxiosNetwork.get(contructor.url, {
          params: {
            limit: '25',
            ...contructor.params
          }
        })
        if (speedTests.data[0].date !== lastSpeedTest.date) {
          foundNewSpeedTestResult = true
          setSpeedTests(speedTests.data)
          setSpeedTestInProgress(false)
        }
      }
    } catch (error) {
      notify(
        'error',
        'Sucedio un error al momento de ejecutar la prueba de velocidad'
      )
      setSpeedTestInProgress(false)
      return false
    }
  }

  return { handleStartNewSpeedTest, speedTestInProgress }
}

export default useSpeedTest
