import React from 'react'
import { Grid } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import useUserData from 'hooks/useUserData'
import { TspeedTest } from 'types/types'

const SpeedTests = () => {
  const { speedTests } = useUserData()
  return (
    <div className='serviceScoreContent-speedtests'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer className='speedtests-tableContainer'>
            <Table
              className='serviceScore-speedtests-table'
              stickyHeader
              size='small'
              aria-label='a dense table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Fecha y Hora</TableCell>
                  <TableCell>Descarga</TableCell>
                  <TableCell>Subida</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {speedTests?.map((row: TspeedTest, index: number) => {
                  let formatedDate = dayjs(row.date).format('DD/MM hh:mm A')
                  return (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{formatedDate}</TableCell>
                      <TableCell>{Math.round(row.download)}</TableCell>
                      <TableCell>{Math.round(row.upload)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default SpeedTests
