/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Status from './Status/ServiceStatus'
import NetworkDevices from './NetworkDevices/NetworkDevices'
import usePrivateAxiosNetwork from 'hooks/usePrivateAxiosNetwork'
import { networkUrlBuilder } from 'utils/urls'
import ReportProblem from 'components/shared/ReportProblem'
import { Grid, IconButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import RefreshIcon from '@mui/icons-material/Refresh'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import useUserData from 'hooks/useUserData'
//import useGoogleTagManager from 'hooks/useGoogleTagManager'

const ServiceStatus = () => {
  const {
    setGlobalLoading,
    isGlobalLoading,
    userData,
    setNetworkData,
    setNetworkDevices
  } = useUserData()
  const { newEvent } = useGoogleAnalytics()
  //const { newEvent } = useGoogleTagManager()
  const privateAxiosNetwork = usePrivateAxiosNetwork()
  //const [enabled, setEnabled] = useState(false)
  const navigate = useNavigate()

  const handleGetServiceStatus = async () => {
    try {
      const { url, params } = networkUrlBuilder(
        userData.network,
        'getCustomerServiceStatus'
      )
      const status = await privateAxiosNetwork.get(url, {
        params: params
      })
      setNetworkData(status.data)
    } catch (error) {
      return false
    }
  }

  const handleGetNetworkDevices = async () => {
    try {
      const { url, params } = networkUrlBuilder(
        userData.network,
        'getCustomerNetworkDevices'
      )
      const networkDevices = await privateAxiosNetwork.get(url, {
        params: params
      })
      setNetworkDevices(networkDevices.data)
    } catch (error) {
      return false
    }
  }

  const handlGetData = async () => {
    setGlobalLoading(true)
    newEvent('action_buttons', 'refresh_network')
    await Promise.all([handleGetServiceStatus(), handleGetNetworkDevices()])
    /*     await handleGetServiceStatus()
    await handleGetNetworkDevices() */
    setGlobalLoading(false)
  }

  useEffect(() => {
    isGlobalLoading && setGlobalLoading(false)
  }, [])

  return (
    <div className='serviceStatusContent'>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item container xs justifyContent='center' alignItems='center'>
          <IconButton
            className='navigationButton axtel-icon-rounded'
            color='primary'
            aria-label='upload picture'
            component='label'
            onClick={() => navigate('/home')}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <h3 className='serviceStatus-title'>Estado del Servicio</h3>
        </Grid>
        <Grid item xs>
          <IconButton
            className='navigationButton'
            color='primary'
            aria-label='upload picture'
            component='label'
            onClick={handlGetData}
          >
            <RefreshIcon className='gtm_refresh_button' />
          </IconButton>
        </Grid>
      </Grid>
      <div className='content-components'>
        <Status />
        <NetworkDevices />
        <ReportProblem />
      </div>
      {/*       {isGlobalLoading && <></>}
      {!isGlobalLoading && enabled && (
        <>
          <Status />
        </>
      )} */}
    </div>
  )
}

export default ServiceStatus
