/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import usePrivateAxiosNetwork from 'hooks/usePrivateAxiosNetwork'
import useUserData from 'hooks/useUserData'
import { networkUrlBuilder } from 'utils/urls'

const useWifiData = () => {
  const privateAxiosNetwork = usePrivateAxiosNetwork()
  const {
    setConnectedDevices,
    userData,
    setGlobalLoading,
    isGlobalLoading,
    setSpeedTests
  } = useUserData()
  const [enabled, setEnabled] = useState<boolean>(false)

  useEffect(() => {
    setGlobalLoading(true)
    const handlGetData = async () => {
      isGlobalLoading && setGlobalLoading(true)
      await handleGetConnectedDevices()
      await handleGetSpeedTests()
      setGlobalLoading(false)
    }
    handlGetData()
      .then(() => {
        setEnabled(true)
      })
      .catch((error) => {
        /*         notify('error', 'Error al obtener los datos del cliente')
              setEnabled(false) */
      })
      .finally(() => {
        isGlobalLoading && setGlobalLoading(false)
      })
  }, [])
  const handleGetConnectedDevices = async () => {
    try {
      const { url, params } = networkUrlBuilder(
        userData.network,
        'getConnectedDevices'
      )
      const connectedDevices = await privateAxiosNetwork.get(url, {
        params: params
      })
      setConnectedDevices(connectedDevices.data)
    } catch (error) {
      return false
    }
  }

  const handleGetSpeedTests = async () => {
    try {
      const { url, params } = networkUrlBuilder(
        userData.network,
        'getSpeedTests'
      )
      const status = await privateAxiosNetwork.get(url, {
        params: {
          ...params,
          limit: 25
        }
      })
      setSpeedTests(status.data)
    } catch (error) {
      return false
    }
  }

  return { enabled }
}

export default useWifiData
