import React from 'react'
import { Grid } from '@mui/material'
import greenDot from 'assets/images/greenDot.png'
import redDot from 'assets/images/redDot.png'
import useUserData from 'hooks/useUserData'

const Status = () => {
  const { networkData } = useUserData()

  let serviceStatusLed, serviceStatusText

  if (networkData.health.internet_status === 'connected') {
    serviceStatusLed = greenDot
    serviceStatusText = 'Internet conectado'
  } else {
    serviceStatusLed = redDot
    serviceStatusText = 'Internet desconectado'
  }

  return (
    <div className='serviceStatusContent-status'>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <div className='status-led'>
            <img alt='' src={serviceStatusLed}></img>
          </div>
        </Grid>
        <Grid item xs={10}>
          <h4 className='status-title'>{serviceStatusText}</h4>
          <h5 className='status-ago'>hace 1 min</h5>
        </Grid>
      </Grid>
    </div>
  )
}

export default Status
