/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useUserData from 'hooks/useUserData'
import useAdministratorLogin from 'hooks/useAdministratorLogin'

const AdministratorLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { auth } = useUserData()
  const verifyToken = useAdministratorLogin()

  useEffect(() => {
    let isMounted = true
    const getTokenValidation = async (adminToken: string) => {
      try {
        await verifyToken(adminToken)
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }
    var search = window.location.search
    var params = new URLSearchParams(search)
    var administratorToken = params.get('adminToken')
    if (administratorToken !== null && !auth) {
      getTokenValidation(administratorToken)
    } else {
      setIsLoading(false)
    }
    return () => {
      isMounted = false
    }
  }, [])

  return <>{isLoading ? <></> : <Outlet />}</>
}

export default AdministratorLogin
