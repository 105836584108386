import usePrivateAxios from 'hooks/usePrivateAxios'
import useUserData from './useUserData'
import { urls } from 'utils/urls'

const useAdministratorLogin = () => {
  const { setUserData } = useUserData()
  const privateAxios = usePrivateAxios()
  const verifyToken = async (adminToken: string) => {
    try {
      const { data: validateToken } = await privateAxios.post(
        urls.postAdministratorTokenValidation,
        {
          adminToken
        }
      )
      setUserData(validateToken)
    } catch (err) {
      console.error(err)
    } finally {
    }
  }
  return verifyToken
}

export default useAdministratorLogin
