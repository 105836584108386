import TagManager from 'react-gtm-module'
import useUserData from './useUserData'

const useGoogleTagManager = () => {
  const { userData, auth } = useUserData()
  const newEvent = async (category: string, event: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...(auth &&
          userData.customerId !== undefined && { userId: userData.customerId })
      }
    })
  }

  const newPageView = async (pathname: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: pathname,
        ...(auth &&
          userData.customerId !== undefined && { userId: userData.customerId })
      }
    })
  }
  return { newPageView, newEvent }
}

export default useGoogleTagManager
