/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Grid, Typography, TextField, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import useUserData from 'hooks/useUserData'
import useSetPassword from './hooks/useSetPassword'
import axtelLogo from 'assets/images/axtel-logo-blanco.png'
import { useNavigate } from 'react-router-dom'

const SetPassword = () => {
  const { token } = useParams()
  const { isGlobalLoading } = useUserData()
  const {
    handleUpdatePassword,
    handleChange,
    isSubmitDisabled,
    email,
    enabled,
    passwords
  } = useSetPassword(token as string)
  const navigate = useNavigate()

  return (
    <div className='SetPasswordContent axtel-background'>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={10} md={6} lg={6}>
          <img src={axtelLogo} alt='' style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant='h4' style={{ color: 'white' }}>
            Portal Clientes
          </Typography>
        </Grid>
        {!isGlobalLoading && !enabled && (
          <>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography
                variant='h6'
                className='SetPassword-Subtitle'
                style={{ color: 'white' }}
              >
                Esta liga ha expirado
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Button
                fullWidth
                className={'axtel-button-full'}
                type='submit'
                size='large'
                onClick={() => navigate('/login')}
              >
                Regresar al inicio
              </Button>
            </Grid>
          </>
        )}
        {!isGlobalLoading && enabled && (
          <>
            <Grid item xs={12}>
              <Typography
                variant='h6'
                gutterBottom
                className='SetPassword-Subtitle'
                style={{ color: 'white' }}
              >
                Correo electrónico
              </Typography>
              <Typography
                variant='h6'
                gutterBottom
                className='SetPassword-Subtitle-email'
                style={{ color: 'white' }}
              >
                {email}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    color: 'white'
                  }
                }}
                id='SetPassword-newPassword'
                label='Nueva contraseña'
                onChange={handleChange}
                className='axtel-textfield-white'
                name='newPassword'
                variant='standard'
                type='password'
                value={passwords.newPassword}
                helperText='Al menos 6 caracteres, una mayúscula, una minúscula, un número y un símbolo.'
              ></TextField>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    color: 'white'
                  }
                }}
                id='SetPassword-confirmPassword'
                onChange={handleChange}
                label='Confirmar contraseña'
                name='confirmPassword'
                variant='standard'
                className='axtel-textfield-white'
                type='password'
                value={passwords.confirmPassword}
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                fullWidth
                disabled={isSubmitDisabled()}
                className={!isSubmitDisabled() ? 'axtel-button-full' : ''}
                //variant='contained'
                type='submit'
                onClick={handleUpdatePassword}
              >
                Establecer contraseña
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default SetPassword
