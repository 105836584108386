import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useUserData from 'hooks/useUserData'

const RequireAuth = () => {
  const { auth } = useUserData()
  const location = useLocation()

  if (auth) {
    if (location.search.includes('adminToken')) {
      return <Navigate to='/' replace />
    } else {
      return <Outlet />
    }
  } else {
    return <Navigate to='/login' state={{ from: location }} replace />
  }
}

export default RequireAuth
