/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react'
import { Grid } from '@mui/material'
import useUserData from 'hooks/useUserData'
import ReportProblem from 'components/shared/ReportProblem'
import ConnectedDevices from './ConnectedDevices'
import DisconnectedDevices from './DisconnectedDevices'
import { Tabs, Tab } from '@mui/material'
import { networkUrlBuilder } from 'utils/urls'
import usePrivateAxiosNetwork from 'hooks/usePrivateAxiosNetwork'
import _ from 'lodash'

const TabPanel = ({
  children,
  value,
  index
}: {
  children: ReactElement | ReactElement[] | undefined
  value: number
  index: number
}) => {
  //const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && children}
    </div>
  )
}

const Devices = () => {
  const privateAxiosNetwork = usePrivateAxiosNetwork()
  const { userData, connectedDevices, setConnectedDevices, setGlobalLoading } =
    useUserData()
  const [value, setValue] = React.useState(0)
  const handleChange = (newValue: number) => {
    setValue(newValue)
  }

  const handleGetConnectedDevices = async () => {
    try {
      const { url, params } = networkUrlBuilder(
        userData.network,
        'getConnectedDevices'
      )
      const connectedDevices = await privateAxiosNetwork.get(url, {
        params: params
      })
      setConnectedDevices(connectedDevices.data)
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    const handlGetDevices = async () => {
      setGlobalLoading(true)
      await handleGetConnectedDevices()
    }
    if (_.isEmpty(connectedDevices))
      handlGetDevices()
        .then(() => {
          //setEnabled(true)
        })
        .catch((error) => {
          /*         notify('error', 'Error al obtener los datos del cliente')
        setEnabled(false) */
        })
        .finally(() => {
          setGlobalLoading(false)
        })
  }, [])

  return (
    <div className='serviceStatusContent-devices'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='devices-title'>
            <h4 className='status-title axtel-text'>Dispositivos</h4>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={(e, value) => handleChange(value)}
            centered
          >
            <Tab
              label={
                'Conectados ' +
                connectedDevices?.filter((device) => device.connected).length
              }
              className='axtel-text'
            />
            <Tab
              label={
                'Desconectados ' +
                connectedDevices?.filter((device) => !device.connected).length
              }
              className='axtel-text'
            />
          </Tabs>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            paddingTop: '30px !important',
            paddingBottom: '30px !important',
            height: connectedDevices.length <= 3 ? 'auto' : '400px',
            overflowY: 'scroll'
          }}
        >
          <Grid item xs={12}>
            <TabPanel value={value} index={0}>
              <ConnectedDevices />
            </TabPanel>
          </Grid>

          <Grid item xs={12}>
            <TabPanel value={value} index={1}>
              <DisconnectedDevices />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
      <ReportProblem />
    </div>
  )
}

export default Devices
