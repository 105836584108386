import React from 'react'
import { Grid } from '@mui/material'
import useUserData from 'hooks/useUserData'

const Status = () => {
  const { networkData } = useUserData()
  return (
    <div className='wifiContent-status-internetStatus'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className='internetStatus-title axtel-text'>
            Estado de internet
          </h4>
          <h5
            className='internetStatus-status '
            style={{
              color:
                networkData.health.internet_status === 'connected'
                  ? '#2CBE5D'
                  : '#E6204F'
            }}
          >
            {networkData.health.internet_status === 'connected'
              ? 'Conectado'
              : 'Desconectado'}
          </h5>
        </Grid>
      </Grid>
    </div>
  )
}

export default Status
