/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import usePrivateAxiosNetwork from 'hooks/usePrivateAxiosNetwork'
import { networkUrlBuilder } from 'utils/urls'
import ReportProblem from 'components/shared/ReportProblem'
import { Grid, IconButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import Score from './Score/Score'
import SpeedTests from './SpeedTests/SpeedTests'
import useUserData from 'hooks/useUserData'

const ServiceScore = () => {
  //const [speedTests, setSpeedTests] = useState([])
  const { setGlobalLoading, isGlobalLoading, userData, setSpeedTests } =
    useUserData()
  const [enabled, setEnabled] = useState(false)
  const privateAxiosNetwork = usePrivateAxiosNetwork()
  const navigate = useNavigate()

  const handleGetSpeedTests = async () => {
    try {
      const { url, params } = networkUrlBuilder(
        userData.network,
        'getSpeedTests'
      )
      const status = await privateAxiosNetwork.get(url, {
        params: {
          limit: '25',
          ...params
        }
      })
      setSpeedTests(status.data)
    } catch (error) {
      return false
    }
  }

  const handlGetData = async () => {
    handleGetSpeedTests()
  }

  useEffect(() => {
    setGlobalLoading(true)
    handlGetData()
    setGlobalLoading(false)
    setEnabled(true)
  }, [])

  return (
    <div className='serviceScoreContent'>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item container xs justifyContent='center' alignItems='center'>
          <IconButton
            className='navigationButton axtel-icon-rounded'
            color='primary'
            aria-label='upload picture'
            component='label'
            onClick={() => navigate(-1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <h3 className='serviceScore-title axtel-text'>Velocidad</h3>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
      {isGlobalLoading && <></>}
      {!isGlobalLoading && enabled && (
        <div className='content-components'>
          <Score />
          <SpeedTests />
          <ReportProblem />
        </div>
      )}
    </div>
  )
}

export default ServiceScore
