import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Button, Stack } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import useUserData from 'hooks/useUserData'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import spinner from 'assets/images/spinner_green.gif'
import useSpeedTest from '../hooks/useSpeedTest'

const SpeedTest = () => {
  const { speedTests } = useUserData()
  const { handleStartNewSpeedTest, speedTestInProgress } = useSpeedTest()
  const navigate = useNavigate()
  let lastSpeedTestText
  if (speedTests.length > 0) {
    let lastSpeedTest = speedTests[0]
    let dif = dayjs().diff(lastSpeedTest.date, 'minute')
    switch (true) {
      case dif <= 1:
        lastSpeedTestText = 'hace menos de un minuto'
        break
      case dif > 1 && dif < 60:
        lastSpeedTestText = 'hace ' + dif + ' minutos'
        break
      case dif >= 60 && dif < 1440:
        lastSpeedTestText = 'hace ' + Math.round(dif / 60) + ' hrs'
        break
      default:
        lastSpeedTestText = 'hace ' + Math.round(dif / 1440) + ' días'
        break
    }
  } else {
    lastSpeedTestText = 'Ninguna prueba realizada'
  }

  return (
    <div className='wifiContent-status-speedTest'>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          alignItems='flex-start'
          justifyContent='flex-start'
        >
          <Grid item xs={10}>
            <h4 className='speedTest-title axtel-text'>Prueba tu velocidad</h4>
            <h5 className='speedTest-ago axtel-text'>{lastSpeedTestText}</h5>
          </Grid>
          {speedTestInProgress && (
            <Grid item xs={1}>
              <img alt='' className='speedTest-spinner' src={spinner} />
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            spacing={2}
          >
            <Grid item container xs={10}>
              <Grid item xs={12}>
                <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  spacing={2}
                  sx={{
                    marginBottom: '5px'
                  }}
                >
                  <div className='speedTest-speed'>
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <p className='axtel-text'>
                        {Math.round(speedTests[0]?.download)}
                      </p>
                      <DownloadIcon className='axtel-icon' />
                    </Stack>
                    <p className='axtel-text'>Descarga</p>
                  </div>
                  <div className='speedTest-speed'>
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <p className='axtel-text'>
                        {Math.round(speedTests[0]?.upload)}
                      </p>
                      <UploadIcon className='axtel-icon' />
                    </Stack>
                    <p className='axtel-text'>Subida</p>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Button
                  //variant='outlined'
                  className='axtel-button-outlined'
                  onClick={() => handleStartNewSpeedTest()}
                  sx={{
                    borderRadius: '13px',
                    marginTop: '20px'
                  }}
                >
                  Realizar prueba de velocidad
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className='navigationButton axtel-icon-rounded'
                //color='primary'

                aria-label='upload picture'
                component='label'
                onClick={() => navigate('/internetScore')}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SpeedTest
